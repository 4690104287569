
import Vue from 'vue';

export default Vue.extend({
    name: 'index',
    components: {},
    data() {
        return {

        }
    },
    created() {
        this.postUserInfo();
    },
    methods: {
        goPage() {
            this.$router.replace({path:'/home' });
        },
        async postUserInfo() {
            try {
                const result = await this.$api.postUserInfo({userId: 'bbb123'});
                console.log('result', result);
            } catch (error) {
                console.log('error', error)
            }
        }
    }
});
